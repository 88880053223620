import React from 'react';
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Menu from './menu';
import Footer from './footer';
import * as css from './template.module.less';

export const query = graphql
    `
    query PostsByID($id: String!) {
        mdx(
            id: { eq: $id }
        ){
            body
            frontmatter {
                title
                date(formatString: "YYYY MMMM Do")
            }
        }
    }
`

const BlogTemplate = ({ data }) => {
    const { frontmatter, body } = data.mdx
    return (
        <>
            <Menu />
            <div className={css.article}>
                <h1 className={css.blogTitle}>{frontmatter.title}</h1>
                <p className={css.data}>{frontmatter.date}</p>
                <MDXRenderer>{body}</MDXRenderer>
            </div>
            <Footer />
        </>
    )
}

export default BlogTemplate;
