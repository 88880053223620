import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Link } from 'gatsby';
import * as css from './menu.module.less';

const Menu = () => {
    const { title } = useSiteMetadata()
    return (
        <div className={css.menu}>
            <div className={css.title}>{title}</div>
            <div className={css.home}>
                <Link to="/">
                    Home
                </Link>
            </div>
        </div>
    )
}

export default Menu;
