import React from "react";

const footerStyle = {
    backgroundColor: '#ddd',
    textAlign: 'center',
    bottom: 0,
    width: '100vw',
    height: '2rem',
    lineHeight: '2rem',
};
const textStyle = {
    textDecoration: 'none',
    fontSize: '0.6rem',
    color: '#666',
};

const Menu = () => {
    return (
        <div style={footerStyle}>
            <a style={textStyle} href="https://beian.miit.gov.cn">粤ICP备16013505号</a>
        </div>
    )
}

export default Menu;
